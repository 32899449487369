import { Box, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import React, { FunctionComponent } from "react";
import IFrameComponent from "../i-frame-component";

const HEIGHT_SM = "220px";
const HEIGHT = "350px";
const WIDTH = "180px";

const text = {
  title: "Overview",
  memoryCapacityTitle: "Memory Capacity",
  memoryCapacity:
    "https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=6",
  memoryUsageTitle: "Memory Usage",
  memoryUsage:
    "https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=8",
  cpuCapacityTitle: "CPU Capacity",
  cpuCapacity:
    "https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=2",
  cpuUsageTitle: "CPU Usage",
  cpuUsage:
    "https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=10",
  nodesTitle: "Nodes",
  nodes:
    "https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=22",
  memoryUsageGraphTitle: "Memory Usage",
  memoryUsageGraph:
    "https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=16",
  cpuUsageGraphTitle: "CPU Usage",
  cpuUsageGraph:
    "https://blueocean-dev-vm.ignw.io/monitoring/d-solo/oyWhi0MMz/cluster?orgId=1&var-clusterName=Pod4-HX01&panelId=18",
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mainGrid: {
      padding: theme.spacing(1, 2, 0, 2),
    },
    text: {
      padding: theme.spacing(0, 0, 2),
    },
  })
);

const ClusterDashboard: FunctionComponent<{}> = () => {
  const classes = useStyles();
  return (
    <Box flex={1} className={classes.mainGrid}>
      <Box mb={1}>
        <Grid container direction={"row"} spacing={1}>
          <Grid item>
            <DeveloperBoardIcon />
          </Grid>
          <Grid item>
            <Typography color="secondary" variant="h5">
              {text.title}
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.memoryCapacityTitle}
            src={text.memoryCapacity}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.memoryUsageTitle}
            src={text.memoryUsage}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.cpuCapacityTitle}
            src={text.cpuCapacity}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={3}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.cpuUsageTitle}
            src={text.cpuUsage}
          />
        </Grid>
        <Grid item xs={12}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT_SM}
            title={text.nodesTitle}
            src={text.nodes}
          />
        </Grid>
        <Grid item xs={12}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.memoryUsageGraphTitle}
            src={text.memoryUsageGraph}
          />
        </Grid>
        <Grid item xs={12}>
          <IFrameComponent
            minWidth={WIDTH}
            minHeight={HEIGHT}
            title={text.cpuUsageGraphTitle}
            src={text.cpuUsageGraph}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ClusterDashboard;
