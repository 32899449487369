import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import React, { FunctionComponent } from "react";
import CardHeading from "../card-heading/CardHeading";
import {
  PowerStateChart,
  PowerStateChartProps,
} from "../charts/PowerStateChart";
import {
  ContainerCard,
  ContainerCardProps,
} from "../surfaces/visualization/ContainerCard";

export interface PowerStateProps extends ContainerCardProps {
  data: PowerStateChartProps["data"];
}

const staticText = {
  title: "Virtual Machine Power State",
};

export const PowerState: FunctionComponent<PowerStateProps> = ({
  data,
  height,
  ...props
}) => {
  return (
    <ContainerCard {...props}>
      <Box flex={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <CardHeading title={staticText.title} />
          </Grid>
          <Grid item xs={12} style={{ height }}>
            <PowerStateChart data={data} />
          </Grid>
        </Grid>
      </Box>
    </ContainerCard>
  );
};

export default PowerState;
