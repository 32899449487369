import React from "react"
import { useTranslation } from "react-i18next"

import Layout from "../../components/layout/Layout"
import SEO from "../../components/seo/SEO"
import ClusterDashboard from "../../components/cluster-dashboard";
import PowerState from '../../components/power-state/PowerState'
import dashboardMockData from '../../components/dashboard/dashboard-mock-data'

const ComputePage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO title={t("compute.title")} />
          <ClusterDashboard/>
          <PowerState data={dashboardMockData.powerState} padding={'18px'} height={300} />
    </Layout>
  )
}

export default ComputePage
